<template>
  <div>
    <el-form :inline="true" size="mini">
      <el-form-item label="操作员">
        <el-select v-model="operatorId" filterable>
          <el-option
            v-for="item in operators"
            :key="item.id"
            :value="item.id"
            :label="item.operatorName"
          >
            <template>
              <div style="float: left">{{ item.operatorName }}</div>

              <div style="float: right; ">
                <el-tag :type="item.isAdmin?'success':'warning'">{{item.isAdmin?'管理员':'操作员'}}</el-tag>
              </div>
            </template>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-tag v-show="operator.isStop" type="danger">账号已停用</el-tag>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="save" :disabled="operatorId&&operator.isAdmin">保存</el-button>
      </el-form-item>
    </el-form>
    <div v-show="operatorId&&!operator.isAdmin">
      <div v-for="item in permission" :key="item.name">
        <h3>{{item.name}}</h3>
        <el-table :data="item.array" border stripe>
          <el-table-column label="类别" prop="label" width="100"></el-table-column>
          <el-table-column label="权限">
            <template slot-scope="scope">
              <el-checkbox-group v-model="operator.permission">
                <el-checkbox
                  v-for="child in scope.row.children"
                  :label="child.value"
                  :key="child.value"
                >{{child.label}}</el-checkbox>
              </el-checkbox-group>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import permission from "@/permission.json";
export default {
  data() {
    return {
      operatorId: "",
      operators: [],
      operator: {
        permission: []
      },
      permission: []
    };
  },
  watch: {
    operatorId: function(val) {
      if (!val) {
        return;
      }
      this.operators.forEach(d => {
        if (d.id == this.operatorId) {
          this.operator = d;
        }
      });
    }
  },
  mounted() {
    this.$get("Operator/GetAllPermission").then(r => {
      let all = r;
      let res = [];
      for (let i in permission) {
        let array = [];
        permission[i].forEach(item => {
          let children = [];
          item.children.forEach(child => {
            if (all.indexOf(child.value) > -1) {
              children.push(child);
            }
          });
          item.children = children;
          if (children.length > 0) {
            array.push(item);
          }
        });
        if (array.length > 0) {
          res.push({
            name: i,
            array: array
          });
        }
      }
      this.permission = res;
    });
  },
  activated() {
    this.$get("Operator/GetAllOperatorPermission").then(r => {
      this.operators = r;
    });
  },
  methods: {
    save() {
      if (!this.operatorId) {
        this.$message.error("请选择操作员");
        return;
      }
      this.$post("Operator/SetOperatorPermission", {
        operatorId: this.operatorId,
        permissions: this.operator.permission
      }).then(r => {
        this.$message.success("保存成功");
      });
    }
  }
};
</script>

<style scoped>
* >>> h3 {
  margin: 8px 0 8px 0;
}
</style>